import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import {
  ERROR,
  GREY_DARK,
  GREY_DARKEST,
  GREY_LIGHT,
  GREY_MEDIUM,
  GREY_MEDIUM_DARK,
  ICE,
  MAIN_CONTENT_WIDTH,
  PRIMARY,
  SAGE,
  SHADOW_CRISP,
  SLATE,
} from '../constants/cssVars'
import {
  useYMLDownload,
  useExcelDownload,
} from '../hooks/fileDownloads/useYMLDownload'
import { Button, InvertedButton } from './Button'
import { Footer, TextLink } from './Common'
import copyIcon from '../assets/icons/copy_sage.svg'
import { useBashDownload } from '../hooks/fileDownloads/useBashDownload'
import { usePowershellDownload } from '../hooks/fileDownloads/usePowershellDownload'
import { useSelectedRecord } from '../hooks/useSelectedRecord'
import { useRequest } from 'redux-query-react'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { getRecordQuery } from '../actions/queries'
import { WaveLoader } from './Loaders'

import macDownloadMetadataGif from '../assets/instructionalImages/mac/download_metadata.gif'
import macCompressImg from '../assets/instructionalImages/mac/compress.png'
import macPathnameModelZipGif from '../assets/instructionalImages/mac/pathname_model_zip.gif'
import macPathnameScriptGif from '../assets/instructionalImages/mac/pathname_script.gif'
import macUploadGif from '../assets/instructionalImages/mac/upload.gif'

import windowsDownloadMetadataGif from '../assets/instructionalImages/windows/download_metadata.gif'
import windowsCompressImg from '../assets/instructionalImages/windows/compress.png'
import windowsPathnameModelZipGif from '../assets/instructionalImages/windows/pathname_model_zip.gif'
import windowsPathnameScriptGif from '../assets/instructionalImages/windows/pathname_script.gif'
import windowsUploadGif from '../assets/instructionalImages/windows/upload.gif'
import windowsUnblockGif from '../assets/instructionalImages/windows/unblock.gif'
import { Tooltip } from './general/Tooltip'
import { useGetRecordQuery } from '../hooks/messagesAndRequests'

require('dotenv').config()

const CRON_INTERVAL = process.env.REACT_APP_CRON_INTERVAL || '15 minutes'

const Content = styled.div`
  width: ${MAIN_CONTENT_WIDTH}px;
  @media only screen and (max-width: ${MAIN_CONTENT_WIDTH}px) {
    width: 100%;
  }
  color: ${PRIMARY};
  p {
    margin: 20px 0px;
    margin-top: 60px;
  }
`

const FigureWrapper = styled.figure`
  > img {
    max-width: 90%;
    width: 600px;
    margin: 10px 0px;
    border: 1px solid ${GREY_MEDIUM};
    margin-bottom: 3px;
  }
  > figcaption {
    max-width: 90%;
    width: 600px;
    font-style: italic;
  }
  margin: 10px 0px;
  font-size: 0.9em;
  color: ${GREY_MEDIUM_DARK};
`

const UploadErrorNote = styled.div`
  background-color: #d8e4ec;
  padding: 15px;
  margin: 0px -15px;
  font-size: 0.95em;
  box-shadow: ${SHADOW_CRISP};
  margin-top: 15px;
  > div {
    font-size: 1.1em;
    padding: 10px 15px;
    background-color: ${GREY_DARKEST};
    color: red;
    font-family: monospace;
    margin-top: 15px;
  }
`

const ExampleStructureWrapper = styled.div`
  background-color: ${GREY_LIGHT};
  padding: 10px;
  border-radius: 4px;
  font-size: 0.9em;
  color: ${GREY_DARK};
  > div {
    padding-left: 25px;
    margin: 5px;
  }
  > div:first-child {
    padding-left: 0px;
  }
`

const CodeBoxWrapper = styled.div`
  font-size: 1em;
  margin: 10px 0px;
  margin-bottom: 20px;
  padding: 6px 10px;
  background-color: ${SLATE};
  border-left: 6px solid ${SAGE};
  color: ${ICE};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background-color: ${PRIMARY};
  }
  > img {
    width: 18px;
    height: 18px;
  }
  > div {
    font-size: 0.85em;
  }
`

// Copyable code box
const CodeBox = ({ children }) => {
  const [copied, setCopied] = useState(false)
  const copyToClipboard = () => {
    setCopied(true)
    navigator.clipboard.writeText(children)
  }

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => setCopied(false), 1000)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [copied])

  return (
    <CodeBoxWrapper onClick={copyToClipboard}>
      <code>{children}</code>
      {copied ? <div>Copied</div> : <img src={copyIcon} alt="copy squares" />}
    </CodeBoxWrapper>
  )
}

// Steps specific to users with a Mac or other Linux/Unix based system
const MacLinuxInstructions = () => {
  const { recordId } = useParams()
  const [selectedRecord] = useSelectedRecord()
  const downloadYMLFile = useYMLDownload()

  const downloadExcelFile = useExcelDownload()

  const handleYamlDownload = () => {
    downloadYMLFile(selectedRecord, recordId)
  }

  const handleExcelDownload = () => {
    downloadExcelFile(recordId)
  }

  return (
    <div>
      <hr style={{ marginTop: '20px' }} />

      <h3>Instructions</h3>

      <div>
        <p>
          <b>1.</b> Use the buttons below to download and save files:
        </p>
        <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
          <li style={{ marginBottom: '10px' }}>
            <b>a.</b> <b>YML file</b> with the metadata that was entered in the
            form
          </li>
          <li>
            <b>b.</b> <b>Excel spreadsheet template</b> where the input, output,
            and any other files used in the model can be listed
          </li>
        </ul>
        <div style={{ marginTop: '20px' }}>
          <Button style={{ marginRight: '10px' }} onClick={handleYamlDownload}>
            Download Metadata YAML file
          </Button>
          <Button onClick={handleExcelDownload}>
            Download input-output config EXCEL template
          </Button>
        </div>
        <p style={{ marginTop: '30px', fontWeight: 'bold' }}>
          ** The names of the files are autogenerated with a unique identifier
          for each model. Please do not rename the files.
          <br />
        </p>

        <p style={{ marginTop: '30px' }}>
          <b>For example:</b>{' '}
          metadata_TDIS-3c46d762-d5f2-416b-81e3-b983a6dc02a3.yml
          <br />
          Config_input_output_TDIS-3c46d762-d5f2-416b-81e3-b983a6dc02a3.xlsx
        </p>
      </div>

      <p style={{ marginTop: '30px' }}>
        <b>2.</b> Please repeat this process for all models that exist within
        the model root directory which will be uploaded. {' '}
        <b>
          Please ensure that every model in this folder has an associated YML
          and Excel file.
        </b>
      </p>
      <p>
        <b>3.</b> Once all the metadata YML <b>and</b> the corresponding excel
        files have been placed in the appropriate directories, please do the
        following:
      </p>
      <ul>
        <li>
          If you have already uploaded models that are 100% complete and these
          have been <b>approved</b>, please <b>compress/zip</b> your model's
          root directory (for example, HUC_ModelName) and save it as{' '}
          <b>HUC_ModelName.zip</b>. Follow the subsequent instructions to upload
          the compressed file to the 100% Final folder.
        </li>
        <li>
          If the models have <b>NOT</b> yet been approved, please adhere to 
          DCCM's guidelines for uploading.
        </li>
      </ul>

      <p>
        <b>4.</b> Next, Download <b>Storage Explorer</b> ( Ignore if you have it
        in your machine).
      </p>

      <p>
        <b>5.</b> Use your <b>SAS token</b> to connect to <b>ADLS</b>.
      </p>

      <p>
        <b>6.</b> Upload the file to <b>appropriate directory in</b> ADLS.
      </p>
    </div>
  )
}

// Wrapper instructions component, where the user can select their system
export const Instructions = () => {
  const { recordId } = useParams()
  const { accessToken } = useCurrentUser()
  const [selectedRecord] = useSelectedRecord()
  useRequest(
    accessToken && !selectedRecord
      ? getRecordQuery(recordId, accessToken)
      : null
  )
  const history = useHistory()
  const [getRecord, getRecordPending] = useGetRecordQuery(() =>
    history.push(`/app/`)
  )
  const handleDone = () => {
    getRecord(recordId, accessToken)
  }

  let instructionsComponent = null
  // if a system type is selected, show the system's instructions
  if (selectedRecord != null) {
    instructionsComponent = <MacLinuxInstructions />
  }

  return (
    <Content>
      <h2>Upload Instructions</h2>

      <p style={{ marginTop: '30px' }}>
        Prepare the model file directory in below structure for HEC-HMS and
        HEC-RAS models before you initiate the upload.
      </p>

      <ExampleStructureWrapper>
        <div>
          <Tooltip
            style={{ width: '300px' }}
            content="Example: '010000000021_HEC-FDA'. This is the folder containing your model. Replace '<HUC>' with your HUC ID and '<ModelName>' with your model's name.This directory should include sub-folders for<b> Hydrology</b>, Hydraulics, and Other"
          >
            {`<HUC_ModelName>_<HUC_ModelName>/`}
          </Tooltip>
        </div>
        <div>
          <Tooltip content="The Hydrology directory should contain the HEC-HMS model'.">
            HEC-HMS/
          </Tooltip>
        </div>
        <div>
          <Tooltip content="The Hydraulics directory should contain the HEC-RAS model'.">
            HEC-RAS/
          </Tooltip>
        </div>
        <div>
          <Tooltip content="The Other directory should contain models you might want to upload which are neither HEC_RAS nor HEC-HMS models.">
            Other/
          </Tooltip>
        </div>
      </ExampleStructureWrapper>

      {selectedRecord == null && <WaveLoader />}

      {instructionsComponent}

      <div style={{ width: '100%', height: '130px' }}></div>
      <Footer>
        <div>
          <div>
            <InvertedButton
              onClick={() => history.push(`/app/form/${recordId}`)}
            >
              Back
            </InvertedButton>
            <Button
              short={true}
              style={{ width: '180px' }}
              onClick={handleDone}
              isLoading={getRecordPending}
            >
              Done
            </Button>
          </div>
        </div>
      </Footer>
    </Content>
  )
}
