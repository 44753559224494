import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveDropdown } from "../../actions/general";
import { activeDropdownSelector } from "../../selectors/general";

// If the user clicks outside of the currently active dropdown or presses escape, close it. 
export const CloseActiveDropdown = () => {
  const activeDropdownId = useSelector(activeDropdownSelector);
  const dispatch = useDispatch();

  // Close dropdown on outside click
  const handleClick = e => {
    if (activeDropdownId) {
      const dropdownElement = document.getElementById(activeDropdownId);
      // Let the button close the dropdown if clicked
      const dropdownButton = document.getElementById(`${activeDropdownId}_btn`);
      if (dropdownElement && !dropdownElement.contains(e.target) && dropdownButton && !dropdownButton.contains(e.target)) {
        dispatch(setActiveDropdown(null))
      }
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, [handleClick]);


  // Close dropdown on escape press
  const closeOnEscapePress = event => {
    if  (event.key === "Escape"){
      dispatch(setActiveDropdown(null))
    }
  }
  
  useEffect(() => {
      window.addEventListener("keydown", closeOnEscapePress);
      return () => {
          window.removeEventListener("keydown", closeOnEscapePress);
      };
  }, [closeOnEscapePress]);

  return null;
};