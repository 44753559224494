import React from 'react'
import styled from 'styled-components'
import {
  HEADER_HEIGHT,
  PRIMARY_DARK,
  PRIMARY,
  PRIMARY_LIGHT,
  BG_PRIMARY,
} from '../constants/cssVars'

import tdisLogo from '../assets/tdis.png'
import twdbLogo from '../assets/glo.png'
import universityLogo from '../assets/university-logo.png' // Import the university logo

const HeaderWrapper = styled.div`
  position: fixed;
  height: ${HEADER_HEIGHT}px;
  color: ${PRIMARY_DARK};
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background-image: radial-gradient(
    circle at right,
    ${PRIMARY_DARK},
    ${PRIMARY_DARK}
  );
`

const LogosWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px; /* Adjust margin as needed */
`

const TDISLogo = styled.img`
  width: 160px;
  height: 70px;
  margin-left: 8px;
  margin-right: 10px; /* Adjust margin as needed */
  @media only screen and (max-width: 700px) {
    width: 90px;
    height: 34px;
  }
`

const TWDBLogo = styled.img`
  width: 100px;
  height: 100px;
  background-color: transparent;
  border: 1px solid transparent;
  @media only screen and (max-width: 700px) {
    width: 100px;
    height: 100px;
  }
`

const UniversityLogo = styled.img`
  width: 200px; // Adjust size as needed
  height: 80px; // Adjust size as needed
  background-color: transparent;
  border: 1px solid transparent;
  @media only screen and (max-width: 700px) {
    width: 100px;
    height: 100px;
  }
`

const HeaderTitle = styled.span`
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 1.2em;
  font-weight: 600;
  color: ${BG_PRIMARY};
  display: flex;
  align-items: center;
  margin-left: 100px; /* Adjust this value to increase or decrease the shift to the right */
  margin-right: 180px; /* Adjust this as needed to align with your layout preferences */
  &:hover {
    color: ${PRIMARY_LIGHT}; // Optionally keep or remove the hover effect since it's no longer a link
  }
`

export const Logos = () => {
  return (
    <LogosWrapper>
      <TDISLogo src={tdisLogo} alt="TDIS logo" />
      <TWDBLogo src={twdbLogo} alt="Texas Water Development Board Logo" />
    </LogosWrapper>
  )
}

export const LoggedOutHeader = () => {
  return (
    <header>
      <HeaderWrapper>
        <Logos />
        <HeaderTitle>TDISMeta</HeaderTitle>
        <UniversityLogo src={universityLogo} alt="University Logo" />
      </HeaderWrapper>
    </header>
  )
}
