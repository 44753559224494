import React from 'react'
import styled from 'styled-components'
import { ButtonLoader } from './Loaders'
import {
  PRIMARY,
  BG_PRIMARY,
  PRIMARY_DARK,
  MAX_DESKTOP_WIDTH,
  BG_LIGHTER,
  SHADOW_LIGHT,
} from '../constants/cssVars'

export const ButtonContent = styled.button`
  background-color: ${({ secondary, color }) =>
    secondary ? 'none' : color || PRIMARY};
  color: ${({ fontColor, secondary, isLoading, color }) =>
    fontColor ? fontColor : secondary || isLoading ? color || PRIMARY : '#fff'};
  font-size: ${({ prominent }) => (prominent ? '1.2' : '1')}em;
  box-shadow: ${({ secondary }) =>
    secondary ? 'none' : '0 2px 8px rgba(0,0,0,.11)'};
  padding: ${({ padding }) => padding || '10px 25px'};
  min-width: ${({ wide, short, width }) =>
    width ? `${width}px` : wide ? '100%' : short ? '0px' : '250px'};
  text-align: center;
  cursor: pointer;
  border: ${({ secondary, color }) =>
    secondary ? `1px solid ${color || PRIMARY}` : 'none'};
  opacity: ${({ disabled, isLoading }) => (disabled || isLoading ? 0.5 : 1)};
  transition: all 0.2s ease;
  &:focus {
    box-shadow: 0 0 2px 4px rgb(93, 196, 255);
  }
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    ${({ fillOnMobile }) => (fillOnMobile ? 'width: min(100%, 400px);' : '')};
  }
`

export const TableButtonContent = styled(ButtonContent)`
  background-color: ${({ color }) => color || PRIMARY};
  &:hover {
    background-color: ${({ color }) => color || PRIMARY_DARK};
  }
`

export const InvertedButtonContent = styled.button`
  color: ${({ active }) => (active ? BG_PRIMARY : PRIMARY)};
  font-size: 1em;
  padding: 10px 16px;
  position: relative;
  margin-right: 10px;
  min-width: 150px;
  text-align: center;
  cursor: pointer;
  background-color: ${({ active }) => (active ? PRIMARY : BG_LIGHTER)};
  border: 2px solid ${PRIMARY};
  transition: all 0.2s ease;
  box-shadow: ${SHADOW_LIGHT};
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    ${({ fillOnMobile }) => (fillOnMobile ? 'width: min(100%, 400px);' : '')};
  }
`

export const ButtonWrapper = styled.div`
  position: relative;
  display: ${({ wide }) => (wide ? 'block' : 'inline-block')};
  cursor: ${({ disabled, isLoading }) =>
    disabled || isLoading ? 'default' : 'pointer'};
`

export const InvertedButton = ({
  isLoading,
  disabled,
  onClick,
  wrapperStyle,
  ...props
}) => {
  const handleClick = () => {
    onClick()
  }
  return (
    <ButtonWrapper
      style={wrapperStyle}
      onClick={isLoading ? null : handleClick}
      disabled={disabled}
      isLoading={isLoading}
    >
      {isLoading && <ButtonLoader />}
      <InvertedButtonContent
        {...props}
        disabled={disabled}
        isLoading={isLoading}
      />
    </ButtonWrapper>
  )
}

export const Button = ({
  isLoading,
  disabled,
  onClick,
  wide,
  wrapperStyle,
  prominent,
  ...props
}) => {
  const handleClick = () => {
    if (!isLoading && onClick) {
      onClick()
    }
  }
  return (
    <ButtonWrapper
      style={wrapperStyle}
      wide={wide}
      onClick={handleClick}
      disabled={disabled}
      isLoading={isLoading}
    >
      {isLoading && <ButtonLoader />}
      <ButtonContent
        {...props}
        wide={wide}
        disabled={disabled}
        prominent={prominent}
        isLoading={isLoading}
      />
    </ButtonWrapper>
  )
}

const ButtonWithLinkStyle = styled.button`
  background-color: transparent;
  color: ${PRIMARY};
  text-decoration: underline;
  border: none;
  font-weight: 600;
  padding: 0px;
  margin: 0px;
  cursor: pointer;
  font-size: 1em;
  opacity: 1;
  &:hover {
    opacity: 0.8;
  }
  ${({ bodyFont }) =>
    bodyFont
      ? `
    font-family: 'Open Sans', sans-serif;
    text-transform: none;
  `
      : ''}
`

export const LinkLikeButton = ({
  onClick,
  style,
  analyticsName,
  bodyFont,
  children,
}) => {
  const handleClick = (e) => {
    onClick(e)
  }
  return (
    <ButtonWithLinkStyle
      type="button"
      bodyFont={bodyFont}
      style={style}
      onClick={(e) => handleClick(e)}
    >
      {children}
    </ButtonWithLinkStyle>
  )
}
