import moment from 'moment'
import {
  getCollectionIdFromRegion,
  getRegionIdFromCollectionId,
} from '../constants/formOptions'
import { metadataFieldInfo } from '../constants/metadataFields'
import { getDateUTC } from './helpers'

// Converts the database fields to our local-application fields
// See "dbFieldNamesToAppFields" object for name mapping
// Additional formatting changes are applied (e.g. converting a formatted string to an array)
export const convertDbPropertiesToAppFields = (properties) => {
  const dbMetadataFields = Object.keys(properties).reduce((accum, key) => {
    const appFieldName = dbFieldNamesToAppFields[key]
    if (appFieldName != null) {
      accum[appFieldName] = properties[key]

      // if app field accepts multiple values (e.g. should be array type) convert string value to an array
      if (
        metadataFieldInfo[appFieldName] &&
        metadataFieldInfo[appFieldName].acceptsMultipleValues &&
        !Array.isArray(properties[key])
      ) {
        accum[appFieldName] =
          properties[key] && properties[key].length > 0
            ? properties[key].split(',')
            : []
      }
    }
    return accum
  }, {})
  // Sometimes the region is returned as "collection_id", other times as "collection"
  const regionId =
    getRegionIdFromCollectionId(properties.collection_id) ||
    getRegionIdFromCollectionId(properties.collection)
  return {
    tdisIdentifier: properties.tdisIdentifier,
    currentMetadata: {
      ...dbMetadataFields,
      twdbFloodPlanningRegionID: regionId,
    },
    statusInfo: {
      status: properties.status,
      timeOfLastStatusChange: properties.most_recent_status_update,
    },
  }
}

// Converts the application fields to database fields
// See "appFieldNamesToDBFieldNames" object for name mapping
// Additional formatting changes are applied (e.g. converting an array to a formatted string)
export const convertAppFieldsToDbProperties = (values, tdisDataIdentifier) => {
  const transformedValues = Object.keys(values).reduce((newObj, key) => {
    const dbKey = appFieldNamesToDBFieldNames[key]
    let transformedValue = values[key]

    // Check if the value is an array or list, then convert it to a string
    if (Array.isArray(transformedValue)) {
      transformedValue = transformedValue.join(', ') // Convert array to comma-separated string
    }

    if (dbKey) {
      newObj[dbKey] = transformedValue
    }
    return newObj
  }, {})

  return {
    ...transformedValues,
    tdisIdentifier: tdisDataIdentifier,
  }
}

// Database field names to application field names
export const dbFieldNamesToAppFields = {
  region: 'rbfsRegionID',
  huc: 'hydrologicUnitCode',
  city: 'city',
  county: 'county',
  title: 'modelName',
  description: 'modelDescription',
  purpose: 'modelPurpose',
  modelType: 'modelType',
  keywordTerm: 'kewyWordTerm',
  updateFrequency: 'updateFrequency',
  contactAffiliation: 'contactAffiliation',
  contactDepartment: 'contactDepartment',
  contactEmail: 'contactEmail',
  contactName: 'contactName',
  contactPhoneNumber: 'contactPhoneNumber',
  spatialDataType: 'spatialDataType',
  spatialExtentDecimalDegrees: 'spatialExtentDecimalDegrees',
  boundaryName: 'boundaryName',
  horizontalCoordinateSystem: 'horizantalCoordinateSystem',
  modelSoftwareName: 'softwareName',
  modelSoftwareVersion: 'softwareVersion',
  creator: 'creator',
  modelInput: 'modelInput',
  modelOutput: 'modelOutput',
  modelConfigFile: 'modelConfigFile',
  tdisIdentifier: 'tdisIdentifier',
  id: 'id',
  createdBy: 'createdBy',
  createdDate: 'createdDate',
  updatedBy: 'updatedBy',
  updatedDate: 'updatedDate',
  useConstraint: 'useConstraint',
}

// Application field names to database field names
export const appFieldNamesToDBFieldNames = {
  rbfsRegionID: 'region',
  hydrologicUnitCode: 'huc',
  city: 'city',
  county: 'county',
  modelName: 'title',
  modelDescription: 'description',
  modelPurpose: 'purpose',
  modelType: 'modelType',
  kewyWordTerm: 'keywordTerm',
  updateFrequency: 'updateFrequency',
  contactAffiliation: 'contactAffiliation',
  contactDepartment: 'contactDepartment',
  contactEmail: 'contactEmail',
  contactName: 'contactName',
  contactPhoneNumber: 'contactPhoneNumber',
  spatialDataType: 'spatialDataType',
  spatialExtentDecimalDegrees: 'spatialExtentDecimalDegrees',
  boundaryName: 'boundaryName',
  horizantalCoordinateSystem: 'horizontalCoordinateSystem',
  softwareName: 'modelSoftwareName',
  softwareVersion: 'modelSoftwareVersion',
  creator: 'creator',
  modelInput: 'modelInput',
  modelOutput: 'modelOutput',
  modelConfigFile: 'modelConfigFile',
  createdBy: 'createdBy',
  createdDate: 'createdDate',
  updatedBy: 'updatedBy',
  updatedDate: 'updatedDate',
  id: 'id',
  tdisIdentifier: 'tdisIdentifier',
  useConstraint: 'useConstraint',
}
