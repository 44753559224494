import React from 'react'
import styled from 'styled-components'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Header } from './Header'
import { Flip, ToastContainer } from 'react-toastify'
import { Login } from './Login'
import { AllMetadataRecords } from './AllMetadataRecords'
import { MetadataForm } from './MetadataForm'
import { CloseActiveDropdown } from './general/CloseActiveDropdown'
import { Instructions } from './Instructions'
import { UploadedView } from './UploadedView'
import SessionExpirationTimer from './general/SessionExpirationTimer'
import { useCurrentUser } from '../hooks/useCurrentUser'

const Wrapper = styled.div`
  width: 100%;
`

const AppWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
`

const AppRoutes = () => {
  return (
    <AppWrapper>
      <Header />
      <Switch>
        <Route path="/app/" exact={true} component={AllMetadataRecords} />
        <Route path="/app/form/:recordId" component={MetadataForm} />
        <Route path="/app/view/:recordId" component={UploadedView} />
        <Route path="/app/instructions/:recordId" component={Instructions} />
        <Redirect to="/app/" />
      </Switch>
      <SessionExpirationTimer />
    </AppWrapper>
  )
}

export const Main = () => {
  const { accessToken } = useCurrentUser()
  return (
    <Wrapper>
      <ToastContainer
        autoClose={3000}
        position="bottom-right"
        transition={Flip}
        hideProgressBar
      />
      <CloseActiveDropdown />
      <main>
        <Switch>
          <Route path="/login" exact={true} component={Login} />
          {accessToken && <Route path="/app" component={AppRoutes} />}
          <Redirect to="/login" />
        </Switch>
      </main>
    </Wrapper>
  )
}
