import moment from 'moment'

const isValidEmail = (email) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
const isValidPhone = (phone) =>
  /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(phone)
const isOnlyNumbers = (str) => /^[0-9]*$/.test(str)
const isOnlyLettersAndNumbers = (str) => /^[A-Za-z0-9]*$/.test(str)
const isEmptyStringOrNull = (val) => val == null || val === ''
const arrayHasValues = (val) => Array.isArray(val) && val.length > 0
const isValidDate = (d) => d instanceof Date && !isNaN(d)

// Model ID: Numeric, 12-digit number. 2-digit Flood Planning Region identifier, followed by regionally-unique 10-digit number.
const isValidModelId = (val) => /^(0|1)\d{11}$/.test(val)

// TWDB FP Project ID: Numeric, 9 digits. 2-digit Flood Planning Region identifier; followed by "1" (for FME), "2" (for FMS),
// or "3" (for FMP); followed by 6-digit unique sequence generated by vendor.
const isValidTwdbFloodPlanningProjectID = (val) =>
  /^(0|1)\d(1|2|3)\d{6}$/.test(val)

// HUC: 12-digit Hydrologic Unit Code(s) identifying the watershed(s).
const isValidHUC = (val) => /^\d{12}$/.test(val)

export const validateMetadata = (metadataFieldInfo) => (values) => {
  const errors = {}
  // return errors;

  if (isEmptyStringOrNull(values.rbfsRegionID)) {
    errors.rbfsRegionID = 'Required'
  }
  if (isEmptyStringOrNull(values.hydrologicUnitCode)) {
    errors.hydrologicUnitCode = 'Required'
  }
  if (isEmptyStringOrNull(values.county)) {
    errors.county = 'Required'
  }
  if (isEmptyStringOrNull(values.city)) {
    errors.city = 'Required'
  }
  if (isEmptyStringOrNull(values.modelName)) {
    errors.modelName = 'Required'
  }
  if (isEmptyStringOrNull(values.creator)) {
    errors.creator = 'Required'
  }
  if (isEmptyStringOrNull(values.modelDescription)) {
    errors.modelDescription = 'Required'
  }
  if (isEmptyStringOrNull(values.modelPurpose)) {
    errors.modelPurpose = 'Required'
  }
  if (isEmptyStringOrNull(values.kewyWordTerm)) {
    errors.kewyWordTerm = 'Required'
  }
  if (isEmptyStringOrNull(values.updateFrequency)) {
    errors.updateFrequency = 'Required'
  }
  if (isEmptyStringOrNull(values.useConstraint)) {
    errors.useConstraint = 'Required'
  }
  if (isEmptyStringOrNull(values.modelType)) {
    errors.modelType = 'Required'
  }
  if (isEmptyStringOrNull(values.softwareName)) {
    errors.softwareName = 'Required'
  }
  if (isEmptyStringOrNull(values.softwareVersion)) {
    errors.softwareVersion = 'Required'
  }
  if (isEmptyStringOrNull(values.contactName)) {
    errors.contactName = 'Required'
  }
  if (isEmptyStringOrNull(values.contactAffiliation)) {
    errors.contactAffiliation = 'Required'
  }
  if (isEmptyStringOrNull(values.contactEmail)) {
    errors.contactEmail = 'Required'
  }
  if (isEmptyStringOrNull(values.contactPhoneNumber)) {
    errors.contactPhoneNumber = 'Required'
  }
  if (isEmptyStringOrNull(values.contactDepartment)) {
    errors.contactDepartment = 'Required'
  }
  if (isEmptyStringOrNull(values.spatialDataType)) {
    errors.spatialDataType = 'Required'
  }
  if (
    isEmptyStringOrNull(values.spatialExtentDecimalDegrees) &&
    isEmptyStringOrNull(values.boundaryName)
  ) {
    errors.spatialExtentDecimalDegrees = 'Required'
    errors.boundaryName = 'Required'
  }

  if (isEmptyStringOrNull(values.horizantalCoordinateSystem)) {
    errors.horizantalCoordinateSystem = 'Required'
  }
  return errors
}

export const validateExample = (values) => {
  const errors = {}
  if (!values.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }
  return errors
}

export const validateContact =
  (isOriginator, contactsById, isCreatingNew) => (values) => {
    const errors = {}
    if (!values.email) {
      errors.email = 'Required'
    } else if (!isValidEmail(values.email)) {
      errors.email = 'Invalid email address'
    } else {
      const emailAlreadyExists = Object.values(contactsById).some(
        (contactObj) => contactObj.email === values.email
      )
      // If not creating new (AKA editing), the email will already exist & is ineditable, so we shouldn't return an error
      if (emailAlreadyExists && isCreatingNew) {
        errors.email =
          'A contact with this email already exists. Please select the contact from existing contacts list instead.'
      }
    }
    if (!values.phoneNumber) {
      errors.phoneNumber = 'Required'
    }
    if (!isOriginator && !values.agency) {
      errors.agency = 'Required'
    }
    if (!values.firstName) {
      errors.firstName = 'Required'
    }
    if (!values.lastName) {
      errors.lastName = 'Required'
    }
    return errors
  }

export const validatePersonalContact = (values) => {
  const errors = {}
  if (!values.phoneNumber) {
    errors.phoneNumber = 'Required'
  }
  if (!values.agency) {
    errors.agency = 'Required'
  }
  if (!values.firstName) {
    errors.firstName = 'Required'
  }
  if (!values.lastName) {
    errors.lastName = 'Required'
  }
  return errors
}

export const validateLogin = (values) => {
  const errors = {}
  if (!values.username) {
    errors.username = 'Username is required'
  }
  // } else if (!isValidEmail(values.email)) {
  //   errors.email = 'Invalid email address';
  // }
  if (!values.password) {
    errors.password = 'Password is required'
  }
  return errors
}

export const validateForgotPassword = (values) => {
  const errors = {}
  if (!values.email) {
    errors.email = 'Email address is required'
  } else if (!isValidEmail(values.email)) {
    errors.email = 'Invalid email address'
  }
  return errors
}

export const validateResetPassword = (values) => {
  const errors = {}
  if (!values.oldPassword) {
    errors.oldPassword = 'Required'
  }

  if (!values.newPassword) {
    errors.newPassword = 'Required'
  } else if (!isOnlyLettersAndNumbers(values.newPassword)) {
    errors.newPassword =
      'Due to usage in scripts, passwords can only contain numbers and upper & lowercase letters (certain symbols are restricted)'
  } else if (values.newPassword.length < 10) {
    errors.newPassword = 'Invalid password, must be 10 characters or more'
  }
  if (!values.newPasswordConfirmation) {
    errors.newPasswordConfirmation = 'Required'
  } else if (values.newPasswordConfirmation !== values.newPassword) {
    errors.newPasswordConfirmation = 'Passwords must match'
  }
  return errors
}
